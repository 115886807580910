<template>
  <div class="review">
    <!-- 当前店铺 -->
    <div class="current-shop">
      当前店铺：
      <el-select
        v-model="queryInfo.merchantId"
        placeholder="请选择店铺"
        clearable=""
        @clear="getReview"
        @change="getReview"
      >
        <el-option
          v-for="item in shopList"
          :key="item.id"
          :label="item.merchantsInfo.shopName"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <!-- 卡片视图区域 -->
    <el-card>
      <div class="block">
        <!-- 搜索与添加区 -->
        <el-row>
          <div class="input">
            <span>根据时间筛选：</span>
            <el-date-picker
              v-model="value1"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              clearable
              @change="handleChangeTime"
              unlink-panels
            >
            </el-date-picker>
          </div>
        </el-row>
      </div>
      <el-table
        :data="reviewList"
        style="width: 100%"
        stripe
        border
      >
        <el-table-column
          prop="id"
          label="评论id"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="shopName"
          label="店铺名称"
        > </el-table-column>
        <!-- <el-table-column  width="120" aligin="center"
                         label="评价详情">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="getAppraiseDetail(scope.row)"
              >评价详情</el-button
            >
          </template>
        </el-table-column> -->
        <el-table-column
          prop="orderId"
          label="订单编号"
        > </el-table-column>
        <el-table-column
          prop="mobile"
          label="会员账号"
        > </el-table-column>
        <el-table-column
          prop="userName"
          label="用户昵称"
        > </el-table-column>
        <el-table-column label="打分">
          <template slot-scope="scope">
            <div>{{ scope.row.score }}分</div>
          </template>
        </el-table-column>
        <el-table-column
          label="评价内容"
          width="250"
          prop="content"
        >
          <!-- <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.content"
              placement="top-start"
              effect="dark"
            >
              <p>{{ scope.row.content }}</p>
            </el-tooltip>
          </template> -->
        </el-table-column>
        <el-table-column
          prop="images"
          width="120"
          label="评价图片"
        >
          <template slot-scope="scope">
            <el-popover
              trigger="hover"
              title=""
              placement="bottom"
              v-if="scope.row.images"
            >
              <img
                :src="scope.row.images[0]"
                width="200"
                height="200"
              />
              <img
                slot="reference"
                :src="scope.row.images[0]"
                style="height: 80px;width: 80px"
              />
            </el-popover>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="evaluatorUserId" label="发表人ID" width="100">
        </el-table-column>
        <el-table-column
          prop="newEvaluatorActor"
          label="提交评论的角色"
          width="160"
        >
          <template slot-scope="scope">
            <el-tag type="primary" v-if="scope.row.evaluatorActor === 0"
              >用户</el-tag
            >
            <el-tag type="primary" v-else-if="scope.row.evaluatorActor === 1"
              >商户</el-tag
            >
          </template>
        </el-table-column> -->
        <el-table-column label="来源">
          <template slot-scope="scope">
            <div v-if="scope.row.online === 0">线下</div>
            <div v-else-if="scope.row.online === 1">线上</div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="replayCommentId"
          label="回复的评价ID"
          width="120"
        >
        </el-table-column> -->
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="150"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <!-- 删除按钮 -->
            <el-button
              type="primary"
              size="mini"
              @click="getAppraiseDetail(scope.row)"
            >查看</el-button>
            <!-- 删除按钮 -->
            <el-button
              type="danger"
              size="mini"
              @click="deleteReview(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 评价详情对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="addReviewDialogVisible"
      width="900px"
      @close="clearInfoDialog"
    >
      <div class="detail">
        <!-- 第一行 -->
        <div class="first">
          <div class="name">店铺名称：{{ commentDetail.shopName }}</div>
          <div class="name">订单编号：{{ commentDetail.orderId }}</div>
          <div class="name">评价时间：{{ commentDetail.createTime }}</div>
        </div>
        <!-- 第二行 -->
        <div class="second">
          <div class="name">会员账号：{{ commentDetail.mobile }}</div>
          <div class="name">会员昵称：{{ commentDetail.userName }}</div>
          <!-- <div class="name">会员打分：{{ commentDetail.score }}分</div> -->
          <div class="name">
            <div v-if="commentDetail.online === 0">来源：线下</div>
            <div v-else>来源：线上</div>
          </div>
        </div>
        <!-- 第三行内容 -->

        <div class="third">
          <div class="name">会员打分：{{ commentDetail.score }}分</div>
          <div
            class="name2"
            style="margin-left:-26px"
          >
            评价内容：{{ commentDetail.content }}
          </div>
        </div>
        <!-- 第四行图片 -->
        <div
          class="four"
          v-if="commentDetail.images"
        >
          评价图片：
          <div
            class="image"
            v-for="(item, index) in commentDetail.images"
            :key="index"
          >
            <img
              :src="item"
              alt=""
            />
          </div>
        </div>
        <!-- 第五行回复 -->
        <!-- <div class="five">
          商家回复：
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 店铺数组
      shopList: [],
      // 店铺请求入参
      shopQueryInfo: {
        limit: 2000,
        pageNo: 1,
        marketId: ''
      },
      queryInfo: {
        limit: 15,
        pageNo: 1,
        merchantId: '',
        marketId: '',
        minCreateTime: undefined,
        maxCreateTime: undefined
      },
      total: 0,
      reviewList: [],
      value1: '',
      addReviewDialogVisible: false,
      // 标题名称
      title: '',
      id: '',
      appraiseImg: [],
      appraiseEvaluatorActor: '',
      appraiseContent: '',
      // 评价详情
      commentDetail: {}
    }
  },
  created () {
    // 取上级路由传送过来的id
    this.queryInfo.merchantId = this.$route.query.id
    this.getReview()
    // 获取商户列表
    this.getMerchantList()
  },
  methods: {
    // 调用商户列表方法
    async getMerchantList () {
      // 赋值marketId
      this.shopQueryInfo.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      const { data: res } = await this.$http.get('merchants/list', {
        params: this.shopQueryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('查看商户失败')
      }
      this.shopList = res.data.data
    },
    handleChangeTime () {
      this.queryInfo.pageNo = 1
      // 当有值的时候请求，当清空的时候，调用列表接口
      if (this.value1 !== null) {
        this.queryInfo.minCreateTime = this.value1[0]
        this.queryInfo.maxCreateTime = this.value1[1]
        this.getReview()
      } else {
        this.queryInfo.minCreateTime = undefined
        this.queryInfo.maxCreateTime = undefined
        this.getReview()
      }
    },
    async getReview () {
      // 赋值marketId
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get(
        '/agriculturalComment/agriculturalCommentList',
        {
          params: this.queryInfo
        }
      )
      // console.log(res)
      this.reviewList = res.data.data
      this.reviewList = res.data.data.map(item => ({
        ...item,
        newEvaluateeActor: item.evaluateeActor === 0 ? '用户' : '商户',
        newEvaluatorActor: item.evaluatorActor === 0 ? '用户' : '商户',
        newOnline: item.online === 0 ? '线下' : '线上'
      }))
      this.total = res.data.total
      // console.log(this.reviewList)
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getReview()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getReview()
    },
    // 删除评论
    async deleteReview (id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该评论，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        `/agriculturalComment/${id}`
      )
      if (res.code !== 0) {
        return this.$message.error('删除评论失败')
      }
      this.$message.success('删除评论成功')
      this.getReview()
    },
    getAppraiseDetail (row) {
      this.title = '评价详情'
      this.commentDetail = row
      this.addReviewDialogVisible = true
    },
    clearInfoDialog () { }
  }
}
</script>

<style lang="less" scoped>
.review {
  position: relative;
}
.current-shop {
  position: absolute;
  top: -60px;
  left: 353px;
}
.logoImg {
  width: 80px;
  height: 80px;
}
// img {
//   width: 100px;
//   height: 100px;
// }
p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
p:hover {
  cursor: pointer;
}
.detail {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.first,
.second,
.five {
  display: flex;
  // justify-content: space-between;
  height: 40px;
  line-height: 40px;
}
.third {
  flex-wrap: wrap;
  display: flex;
  height: 40px;
  line-height: 40px;
}
.four {
  display: flex;
  justify-content: flex-start;
  line-height: 40px;
}
.image img {
  width: 150px;
  height: 150px;
  margin-right: 20px;
}
// .el-tooltip {
//   width: 200px;
// }
.name {
  width: 300px;
}
.name2 {
  width: 500px;
}
</style>
